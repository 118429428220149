$(document).ready(function() {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function(){
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function(e){
		var target = $(this).attr("target"),
			url = $(this).attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    }).click(function(){
		var target = $(this).find("a.js-click-item-link").attr("target"),
			url = $(this).find("a.js-click-item-link").attr("href");

		if (target == "_blank") {
			window.open(url);
		}else {
			window.location = url;
		}
        return false;
    });​



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * Scroll-To
    // *
    // * Smooth-Scroll to targets on page
    // *
    $('a[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                scrollTo(target);
            return false;
            }
        }
    });

    function scrollTo(element) {
        $('html, body').animate({
            scrollTop: element.offset().top - 100
        }, 1000);
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 120; // Distance from Browserbottom & -top where the animation should start

    function fadeInElements(){
        var viewPortStart = $(window).scrollTop(),
            viewPortEnd = viewPortStart + $(window).height();


        $(".animateIn:visible").each(function(){
            var elementTop = $(this).offset().top,
                elementBottom = $(this).offset().top + $(this).outerHeight();

            if ((elementTop + offset) <= viewPortEnd && (elementBottom - offset) >= viewPortStart) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            }
            // }else {
            //     $(this).removeClass("animateIn--active");
            // }
        });
    }

    $(window).scroll(function() {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function() {
        if(location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        }else {
            $(this).attr('target','_blank');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * nav
    // *
    // *
    $(".js-nav-button").click(function() {
        $(".js-nav, .js-header").toggleClass("active");
    })

    $(window).scroll(function() {
        if($(".js-nav").hasClass("active")) {
            $(".js-nav, .js-header").removeClass("active");
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * wordSwitcher
    // *
    // *
    var $wordSwitcher = $(".js-heroswitcher");

    if ($wordSwitcher.length) {
        $wordSwitcher.each(function(){
            $(this).slick({
                infinite: true,
                fade: true,
                dots: false,
                arrows: false,
                autoplay: true,
                autoplaySpeed: 1400,
                variableWidth: false,
                speed: 10,
                // centerMode: true,
                pausOnHover: false,
                pauseOnFocus: false,
                // autoplaySpeed: 7000,
                waitForAnimate: true,
            });
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * descSlider
    // *
    // *
    var $descSlider = $(".js-descslider");

    if ($descSlider.length) {
        $descSlider.each(function(){
            var $descSliderImages = $(this).find(".js-descslider-images"),
                $descSliderContent = $(this).find(".js-descslider-content");

            $descSliderImages.slick({
                infinite: true,
                fade: false,
                dots: true,
                appendDots: $(this).find(".js-descslider-dots"),
                arrows: false,
                autoplay: true,
                speed: 1000,
                asNavFor: $descSliderContent,
                autoplaySpeed: 7000,
                waitForAnimate: false,
            });

            $descSliderContent.slick({
                infinite: true,
                fade: true,
                dots: false,
                arrows: false,
                autoplay: true,
                speed: 1000,
                autoplaySpeed: 7000,
                waitForAnimate: false,
                asNavFor: $descSliderImages,
            });
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * accordion
    // *
    // *
    $(".js-accordion-button").click(function() {
        var $button = $(this);

        // toggle item
        $button.siblings(".js-accordion-content").slideToggle().parent(".js-accordion").toggleClass("active");

        // close other items
        $button.parent(".js-accordion").siblings(".js-accordion.active").removeClass("active").find(".js-accordion-content").slideUp();

        // scroll to top
        setTimeout(function(){
            $('html, body').animate({
                scrollTop: ($button.offset().top - 200)
            }, 400);
        }, 400 );
    })

    // clone image for preview
    $(".js-accordion").each(function() {
        $(this).find(".js-accordion-figure").clone().appendTo($(this).find(".js-accordion-clone"));
    })

    // $(".accordion__filter").on("click", function() {
    //     $(".js-accordion").removeClass("visible");

    //     filterClass = $(this).data("filter");
    //     console.log(filterClass);
    //     $(".js-accordion" + filterClass).addClass("visible");
    // })

    // $(".accordion--portfolio:visible").addClass("visible");

    $(".accordion--team").mouseleave(function() {
        $(this).removeClass("active").find(".js-accordion-content").slideUp();
    })



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * slider
    // *
    // *
    var $slider = $(".js-slider");

    if ($slider.length) {
        $slider.each(function(){
            $sliderWrapper = $(this).parents(".js-slider-wrapper");

            $(this).slick({
                infinite: true,
                fade: false,
                dots: true,
                arrows: true,
                prevArrow: $sliderWrapper.find(".js-slider-nav-prev"),
                nextArrow: $sliderWrapper.find(".js-slider-nav-next"),
                autoplay: true,
                variableWidth: true,
                speed: 1000,
                centerMode: true,
                autoplaySpeed: 7000,
            });

            $(this).slick('slickGoTo',0);
        });
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * preloader
	// *
	// *
    var preloader = sessionStorage.getItem("preloader");

    if (preloader != 1) {
        $(".js-preloader").show();
        sessionStorage.setItem("preloader",1);
	};



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * filter portfolio
    // *
    // *
    if ($(".js-filter-elements").length) {
        var mixer = mixitup('.js-filter-elements', {
            selectors: {
                target: '.js-accordion'
            },
            load: {
                filter: '.all'
            }
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
	// * header
	// *
	// *
    if($(window).width() < 700) {

        var didScroll;
        var lastScrollTop = 0;
        var delta = 5;
        var navbarHeight = $('.js-header').outerHeight();

        $(window).scroll(function(event){
            didScroll = true;
        });

        setInterval(function() {
            if (didScroll) {
                hasScrolled();
                didScroll = false;
            }
        }, 250);

        function hasScrolled() {
            var st = $(this).scrollTop();

            // Make sure they scroll more than delta
            if(Math.abs(lastScrollTop - st) <= delta)
                return;

            // If they scrolled down and are past the navbar, add class .nav-up.
            // This is necessary so you never see what is "behind" the navbar.
            if (st > lastScrollTop && st > navbarHeight){
                // Scroll Down
                $('.js-header').removeClass('nav-down').addClass('nav-up');
            } else {
                // Scroll Up
                if(st + $(window).height() < $(document).height()) {
                    $('.js-header').removeClass('nav-up').addClass('nav-down');
                }
            }

            lastScrollTop = st;
        }

    }





});



$(window).on("load resize scroll", function() {

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * sticky Header
    // *
    // *
    // var height = $(window).height() - 40;

    // if($(window).scrollTop() >= height) {
    //     $(".js-header-landing").removeClass("light");
    // }else {
    //     $(".js-header-landing").addClass("light");
    // }    

    if($(window).scrollTop() >= 100) {
        $(".js-header").addClass("hideName");
    }else {
        $(".js-header").removeClass("hideName");
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * invert header
    // *
    // *
    var invertHeaderPos = $(".js-header").position().top;
    var header = $(".js-header");
    var isAnySectionInView = false;

    $(".js-invert-section").each(function() {
        var sectionStart = $(this).offset().top - $(window).scrollTop() - 1,
            sectionEnd = $(this).offset().top + $(this).outerHeight() - $(window).scrollTop();

        if (invertHeaderPos > sectionStart && invertHeaderPos < sectionEnd) {
            isAnySectionInView = true;
            return false;
        }
    });

    if (isAnySectionInView) {
        header.addClass("light");
      } else {
        header.removeClass("light");
      }
});
